import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import CustomerHeader from '../Header'
import { useDispatch, useSelector } from 'react-redux'
import { getCities, getLanguages, getReligiousSec, getServices, searchCity } from '../../../redux/reducers/homeSlice'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import CitySelect from '../../../components/SelectSearch'


export default function Vendor() {
    const [activeTab, setActiveTab] = useState('personal-info')
    const { services, languages, religious, searchCities } = useSelector(state => state.home)

    const [cityName, setCityName] = useState('');

    const navigate = useNavigate();

    const dispatch = useDispatch();

    let [searchParams, setSearchParams] = useSearchParams();
    const { cities } = useSelector(state => state.home)

    const [loading, setLoading] = useState(false)
    const lastData = sessionStorage.getItem('vendor_data') ? JSON.parse(sessionStorage.getItem('vendor_data')) : {}


    const { control, register, setValue, handleSubmit, watch, clearErrors, setError, formState: { errors } } = useForm({
        defaultValues: {
            ContactInfo: searchParams.get('number') !== 'null' ? searchParams.get('number') : "",  // Default to empty string if null
            SecondaryEmail: searchParams.get('email') !== 'null' ? searchParams.get('email') : "",
            FullName: searchParams.get('name') !== 'null' ? searchParams.get('name') : "",
            Experiences: [
                { JobTitle: '', CompanyName: '', Experience: '', description: '' }
            ],
            ...lastData
        },
        mode: 'onChange'
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            if (cityName) {
                dispatch(searchCity({ searchText: cityName, countryName: null, pageNumber: 1, pageSize: 10 }));
            }
        }, 0);
        return () => {
            clearTimeout(handler);
        };
    }, [cityName, dispatch]);


    const educationOptions = [
        "Some Highschool",
        "Highschool Diploma/GED",
        "Associates Degree",
        "Bachelors Degree",
        "Masters Degree",
        "Doctorate Degree",
    ];
    const educationFocusOptions = [
        'Counseling',
        'Marriage and Family Therapy',
        'Early Childhood Education'
    ]


    // useEffect(() => {
    //     if (lastData && Object.keys(lastData).length > 0) {
    //         Object.keys(lastData).map(key => {
    //             setValue(key, lastData[key])
    //         })
    //     }
    //     // setValue("ContactInfo", 'ali')
    // }, [lastData])

    useEffect(() => {
        dispatch(getServices())
        dispatch(getCities(1, 10))
        dispatch(getLanguages())
        dispatch(getReligiousSec())
    }, [])



    const handleSave = (data) => {
        // console.log()
        const payload = { ...data, ProfiePicture: data?.ProfiePicture[0], Languages: data.Languages.map((lang) => lang.value) }
        sessionStorage.setItem('vendor_data', JSON.stringify(data))
        navigate('/financial-info', { state: payload })
    }



    const { fields, append, remove } = useFieldArray({
        control,
        name: "Experiences",
    });


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )

    const languageOptions = [
        { value: 'english', label: 'English' },
        { value: 'spanish', label: 'Spanish' },
        { value: 'french', label: 'French' },
        { value: 'german', label: 'German' },
        { value: 'chinese', label: 'Chinese' },
        { value: 'japanese', label: 'Japanese' },
    ];



    const loadCities = async (inputValue, callback) => {
        if (inputValue) {
            dispatch(searchCity({
                searchText: inputValue,
                countryName: null,
                pageNumber: 1,
                pageSize: 10
            }))
        }
        const cityOptions = searchCities.length > 0 && searchCities.map(city => ({
            label: city.cityName,
            value: city.cityID,
        }));
        callback(cityOptions);
    };

    return (
        <>
            <CustomerHeader />
            <section className="py-5 title-styled main-vendor" >
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 text-center py-5">
                            <h2>Begin Building Your Provider Profile</h2>
                        </div>
                    </div>
                </div>
                <ul className="nav nav-tabs tabs-style1" id="myTab" role="tablist">
                    <li className="nav-item col-6" role="presentation" onClick={() => setActiveTab('personal-info')}>
                        <a
                            className={`nav-link d-flex justify-content-center ${activeTab == 'personal-info' && 'active'}`}
                            id="personal-info-tab"
                            style={{ cursor: 'pointer' }}
                            // data-bs-toggle="tab"
                            // href="#personal-info"
                            role="tab"
                            // aria-controls="personal-info"
                            aria-selected={activeTab == "personal-info"}
                        >
                            <i className="tik-icon me-2" />
                            Vendor Profile
                        </a>
                    </li>
                    <li className="nav-item col-6 " role="presentation" onClick={() => setActiveTab('business-info')}>
                        <a
                            className={`nav-link d-flex justify-content-center ${activeTab == 'business-info' && 'active'}`}
                            id="business-info-tab"
                            style={{ cursor: 'pointer' }}
                            // data-bs-toggle="tab"
                            // href="#business-info"
                            role="tab"
                            // aria-controls="business-info"
                            aria-selected={activeTab == "business-info"}
                        >
                            <i className="tik-icon me-2" />
                            Availability
                        </a>
                    </li>
                </ul>

                <form onSubmit={handleSubmit(handleSave)}>
                    <div className="tab-content" id="myTabContent">
                        {activeTab == "personal-info" && (
                            <div
                                className={`tab-pane fade show ${activeTab == 'personal-info' && 'active'}`}
                                id="personal-info"
                                role="tabpanel"
                                aria-labelledby="personal-info-tab"
                            >
                                <div className="container py-md-5 pb-3 pt-5">
                                    <div className="row" style={{ marginBottom: '-80px' }}>
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label htmlFor="name" className="form-label fv-label">
                                                    Full Name*
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control f-v"
                                                    disabled={searchParams.get('name') && searchParams.get('name') !== 'null'}

                                                    placeholder="Enter Full Name"

                                                    name='FullName'
                                                    {...register("FullName", { required: "Full Name is required!" })}

                                                />
                                                {errors.FullName && <p className='text-danger'>{errors.FullName.message}</p>}

                                            </div>
                                        </div>

                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer2">
                                                <div className="upload-container text-center">
                                                    <label htmlFor="file-upload" className="upload-label">
                                                        <span className="upload-icon" />
                                                        <span className="upload-caption d-block mt-2">
                                                            Add Profile Picture*
                                                        </span>

                                                    </label><br />

                                                    {watch("ProfiePicture")?.length > 0 && <p >{watch("ProfiePicture")?.length > 0 && watch("ProfiePicture")[0].name}</p>}
                                                    {errors.ProfiePicture && <p className='text-center text-danger' style={{ marginTop: '-5px' }}>{errors.ProfiePicture.message}</p>}
                                                    <input
                                                        id="file-upload"
                                                        type="file"
                                                        accept="image/*"
                                                        name='profilePic'
                                                        {...register("ProfiePicture", { required: "Profile Picture is required!" })}

                                                        style={{ opacity: 0 }}

                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Email*</label>
                                                <input
                                                    type="email"
                                                    className="form-control f-v"
                                                    placeholder="Enter your Email"
                                                    disabled={searchParams.get('email') && searchParams.get('email') !== 'null'}
                                                    name='SecondaryEmail'
                                                    {...register("SecondaryEmail",
                                                        {
                                                            required: "Email is required!",
                                                            pattern: {
                                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                                message: 'Invalid email address'
                                                            }
                                                        })}
                                                />
                                                {errors.SecondaryEmail && <p className='text-danger'>{errors.SecondaryEmail.message}</p>}

                                            </div>
                                        </div>
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Phone*</label>
                                                <input
                                                    type="text"
                                                    className="form-control f-v"
                                                    placeholder="Phone number"
                                                    maxLength={11}
                                                    disabled={searchParams.get('number') && searchParams.get('number') !== 'null'}
                                                    {...register("ContactInfo", {
                                                        required: "Phone Number is required!",
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Phone number must be number!',
                                                        },
                                                    })}
                                                />
                                                {errors.ContactInfo && <p className='text-danger'>{errors.ContactInfo.message}</p>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Service Category*</label>
                                                {/* <select
                                                    className="form-select f-v"
                                                    {...register("MajorServiceId", { required: "Service Category is required!" })}
                                                >
                                                    <option value="">Select Service Category</option>
                                                    {services?.map(service => (
                                                        <option key={service.ServiceID} value={service.ServiceID}>
                                                            {service.ServiceName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.MajorServiceId && (
                                                    <p className="text-danger">{errors.MajorServiceId.message}</p>
                                                )} */}
                                                <Controller
                                                    name="MajorServiceId"
                                                    control={control}
                                                    rules={{ required: "Service Category is required!" }}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            className="form-select f-v"
                                                        >
                                                            <option value="">Select Service Category</option>
                                                            {services?.map(service => (
                                                                <option key={service.ServiceID} value={service.ServiceID}>
                                                                    {service.ServiceName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors.MajorServiceId && (
                                                    <p className="text-danger">{errors.MajorServiceId.message}</p>
                                                )}

                                            </div>
                                        </div>

                                        <div className="col-md-6 my-md-2 my-3" >
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Gender*</label>
                                                <div className="d-flex">
                                                    <label className="align-items-center d-flex me-5">
                                                        <input
                                                            type="radio"
                                                            className="option-input radio"
                                                            name="gender"
                                                            defaultChecked=""
                                                            value={'M'}
                                                            {...register("Gender", { required: "Gender is required!" })}
                                                        />
                                                        Male
                                                    </label>
                                                    <label className="align-items-center d-flex">
                                                        <input
                                                            type="radio"
                                                            className="option-input radio"
                                                            name="gender"
                                                            value={'F'}
                                                            {...register("Gender", { required: "Gender is required!" })}
                                                        />
                                                        Female
                                                    </label>
                                                </div>
                                                {errors.Gender && <p className='text-danger'>{errors.Gender.message}</p>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-4 my-md-2 my-3" >
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Age*</label>
                                                <input
                                                    type="text"
                                                    className="form-control f-v"
                                                    placeholder="Age"
                                                    {...register("Age", {
                                                        required: "Age is required!",
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: "Age must be a number"
                                                        }
                                                    })}
                                                />
                                                {errors.Age && <p className='text-danger'>{errors.Age.message}</p>}

                                            </div>
                                        </div>
                                        <div className="col-md-4 my-md-2 my-3" >
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Religious Sect*</label>
                                                {/* <select
                                                    className="form-select f-v"
                                                    {...register("SectId", { required: "Religious Sect* is required!" })}
                                                >
                                                    <option value=""></option>
                                                    {religious?.map((rel, idx) => (
                                                        <option key={idx} value={rel?.sectID}>{rel?.sectName}</option>
                                                    ))}
                                                </select> */}

                                                <Controller
                                                    name="SectId"
                                                    control={control}
                                                    rules={{ required: "Religious Sect is required!" }}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            className="form-select f-v"
                                                        >
                                                            <option value=""></option>
                                                            {religious?.map((rel, idx) => (
                                                                <option key={idx} value={rel?.sectID}>
                                                                    {rel?.sectName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors.SectId && <span className="text-danger">{errors.SectId.message}</span>}

                                            </div>
                                        </div>
                                        <div className="col-md-4 my-md-2 my-3" >
                                            <div className='fv-outer'>
                                                <label className="form-label fv-label">Languages*</label>
                                                <Controller
                                                    name="Languages"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: 'Languages is required!' }}
                                                    render={({ field }) => (
                                                        <div className='form-control' style={{ border: '1px solid #00cc66' }}>
                                                            <Select
                                                                {...field}
                                                                options={languages?.map((lang) => {
                                                                    return { value: lang?.languageID, label: lang?.languageName }
                                                                })}

                                                                isSearchable
                                                                placeholder="Select a language"
                                                                isMulti

                                                                onChange={(selected) => {
                                                                    console.log('sssssss', selected)
                                                                    if (selected.length < 5) {
                                                                        clearErrors('Languages');
                                                                    } else {
                                                                        setError('Languages', {
                                                                            type: 'manual',
                                                                            message: `You can select up to 4 options.`,
                                                                        });
                                                                    }
                                                                    // onChange(selected);
                                                                    if (selected.length < 5) field.onChange(selected)
                                                                }}
                                                                styles={{
                                                                    control: ((provider) => ({
                                                                        ...provider,
                                                                        border: 'none',
                                                                        height: 'calc(2.25rem + 2px)', // Match Bootstrap's select height
                                                                        borderRadius: '.25rem', // Match Bootstrap's border radius
                                                                        borderColor: '#ced4da', // Bootstrap's border color for selects
                                                                        boxShadow: 'none', // Remove the default React Select shadow
                                                                        '&:hover': {
                                                                            borderColor: '#80bdff', // Match Bootstrap's hover border color
                                                                        },
                                                                    })),
                                                                    clearIndicator: ((provider) => ({
                                                                        ...provider,
                                                                        display: 'none'
                                                                    })),
                                                                    indicatorSeparator: ((provider) => ({
                                                                        ...provider,
                                                                        display: 'none'
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            {errors.Languages && <p className='text-danger'>{errors.Languages.message}</p>}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Brief Intro*</label>
                                                <textarea
                                                    className="form-control f-v txtarea1"
                                                    placeholder="Describe yourself"
                                                    defaultValue={""}
                                                    {...register("BriefIntro", { required: "Brief Intro is required!" })}
                                                />
                                                {errors.BriefIntro && <p className='text-danger'>{errors.BriefIntro.message}</p>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">City to Serve*</label>
                                                {/* <select
                                                    className="form-select f-v"
                                                    {...register("CityToServe", { required: "City to Serve is required!" })}
                                                >
                                                    <option value="">Select City to Serve</option>
                                                    {cities?.map((city, index) => (
                                                        <option key={index} value={city.cityID}>
                                                            {city.cityName}
                                                        </option>
                                                    ))}
                                                </select> */}

                                                <Controller
                                                    name="CityToServe"
                                                    control={control}
                                                    rules={{ required: 'City To Serve is required' }}
                                                    render={({ field }) => (
                                                        <div className='form-control' style={{ padding: '0 0 4px 0', border: '1px solid #00cc66' }}>
                                                            <CitySelect
                                                                {...field}
                                                                loadOptions={loadCities}
                                                                handleChange={({ label, value }) => field.onChange(value)} // Update form state
                                                            />
                                                        </div>
                                                    )}
                                                />

                                                {errors.CityToServe && (
                                                    <p className="text-danger">{errors.CityToServe.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Distance*</label>
                                                <select
                                                    className="form-select f-v"
                                                    {...register("Distance", { required: "Distance is required!" })}
                                                >
                                                    <option value="">Distance</option> {/* Corrected this option */}
                                                    <option value={1}>1 Miles</option>
                                                    <option value={2}>2 Miles</option>
                                                    <option value={3}>3 Miles</option>
                                                </select>

                                                {errors.Distance && (
                                                    <p className="text-danger">{errors.Distance.message}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className="">
                                    <div className="heading-bordered mb-4 pb-1">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4>Add Education</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 my-md-4 my-3">
                                                <div className='fv-outer'>
                                                    <label className="form-label fv-label">
                                                        Highest Level of Education*
                                                    </label>
                                                    <select
                                                        className="form-select f-v"
                                                        {...register('HighestEducationLevel', { required: 'Highest Level of Education is required!' })}
                                                    >
                                                        <option value="">Select options</option>
                                                        {educationOptions.map((edu, idx) => (
                                                            <option key={idx} value={idx + 1}>
                                                                {edu}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.HighestEducationLevel && (
                                                        <p className="text-danger">{errors.HighestEducationLevel.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6 my-md-4 my-3">
                                                <div className='fv-outer'>
                                                    <label className="form-label fv-label">
                                                        Degree Focus*
                                                    </label>
                                                    <select
                                                        className="form-select f-v"
                                                        {...register('DegreeFocus', { required: 'Degree Focus is required!' })}
                                                    >
                                                        <option value="">Select options</option>
                                                        {educationFocusOptions.map((edu, idx) => (
                                                            <option key={idx} value={idx + 1}>
                                                                {edu}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.DegreeFocus && (
                                                        <p className="text-danger">{errors.DegreeFocus.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="py-md-5 py-3">
                                    <div className="heading-bordered mb-4 pb-1">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-10">
                                                    <h4>Add Experience</h4>
                                                </div>
                                                <div className='col-2 text-end'>
                                                    <button
                                                        className='btn'
                                                        style={{ color: '#00CC66' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            append({ JobTitle: '', CompanyName: '', Experience: '', description: '' })
                                                        }}
                                                    >
                                                        <i className='fa fa-plus' />
                                                    </button>
                                                    {fields.length > 1 && <button
                                                        className='btn'
                                                        style={{ color: 'red' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            remove(fields.length - 1)
                                                        }}
                                                    >
                                                        <i className='fa fa-minus' />
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">

                                        {fields.map((exp, index) => (
                                            <div key={exp.id} className="row border-bottom">
                                                <div className="col-md-4 my-md-4 my-3">
                                                    <div className="fv-outer">
                                                        <label htmlFor="JobTitle" className="form-label fv-label">Job Title</label>
                                                        <Controller
                                                            name={`Experiences[${index}].JobTitle`}
                                                            control={control}
                                                            defaultValue={exp.JobTitle}
                                                            rules={{ required: index == 0 ? "Job Title is required" : false }}
                                                            render={({ field }) => (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    className="form-control f-v"
                                                                    placeholder="Enter Job Title"
                                                                />
                                                            )}
                                                        />
                                                        {errors?.Experiences?.[index]?.JobTitle && (
                                                            <p className="text-danger">{errors.Experiences[index].JobTitle.message}</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4 my-md-4 my-3">
                                                    <div className="fv-outer">
                                                        <label htmlFor="CompanyName" className="form-label fv-label">Company Name</label>
                                                        <Controller
                                                            name={`Experiences[${index}].CompanyName`}
                                                            control={control}
                                                            defaultValue={exp.CompanyName}
                                                            rules={{ required: index == 0 ? "Company Name is required" : false }}
                                                            render={({ field }) => (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    className="form-control f-v"
                                                                    placeholder="Enter Company Name"
                                                                />
                                                            )}
                                                        />
                                                        {errors?.Experiences?.[index]?.CompanyName && (
                                                            <p className="text-danger">{errors.Experiences[index].CompanyName.message}</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4 my-md-4 my-3">
                                                    <div className="fv-outer">
                                                        <label htmlFor="Experience" className="form-label fv-label">Experience in years</label>
                                                        <Controller
                                                            name={`Experiences[${index}].Experience`}
                                                            control={control}
                                                            defaultValue={exp.Experience}
                                                            rules={{
                                                                required: index == 0 ? "Experience is required" : false, pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: "Experience must be a number"
                                                                }
                                                            }}
                                                            render={({ field }) => (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    className="form-control f-v"
                                                                    placeholder="Enter Experience"
                                                                />
                                                            )}
                                                        />
                                                        {errors?.Experiences?.[index]?.Experience && (
                                                            <p className="text-danger">{errors.Experiences[index].Experience.message}</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-12 my-md-4 my-3">
                                                    <div className="fv-outer">
                                                        <label className="form-label fv-label">Please describe your experience</label>
                                                        <Controller
                                                            name={`Experiences[${index}].description`}
                                                            control={control}
                                                            defaultValue={exp.description}
                                                            rules={{ required: index == 0 ? "Description is required" : false }}
                                                            render={({ field }) => (
                                                                <textarea
                                                                    {...field}
                                                                    className="form-control f-v txtarea1"
                                                                    placeholder="Describe your experience"
                                                                />
                                                            )}
                                                        />
                                                        {errors?.Experiences?.[index]?.description && (
                                                            <p className="text-danger">{errors.Experiences[index].description.message}</p>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        ))}

                                    </div>
                                </section>
                            </div>
                        )}

                        {activeTab == "business-info" && (
                            <div
                                className={`tab-pane fade show ${activeTab == 'business-info' && 'active'}`}
                                id="business-info"
                                role="tabpanel"
                                aria-labelledby="business-info-tab"

                            >
                                <div className="container py-5">
                                    <div className="row">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label htmlFor="name" className="form-label fv-label">
                                                    Please Select Availability
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control f-v calander-icon"
                                                    placeholder="Select work schedule"


                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="fv-outer">
                                                <div className="calendar-cstm">
                                                    <div className="container">
                                                        <div className="row calendartop align-items-center text-center">
                                                            <div className="col-md-4 offset-md-4">
                                                                <h4>
                                                                    <i className="fa fa-angle-left" /> JULY 2024{" "}
                                                                    <i className="fa fa-angle-right" />
                                                                </h4>
                                                            </div>
                                                            <div className="col-md-4 text-md-end mt-md-0 mt-4">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-1 radius-50"
                                                                >
                                                                    <i className="fa fa-plus me-1 fs-14" />
                                                                    Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-calendar">
                                                        <div className="thead">
                                                            <div className="d-flex justify-content-between">
                                                                <div>S</div>
                                                                <div>M</div>
                                                                <div>T</div>
                                                                <div>W</div>
                                                                <div>T</div>
                                                                <div>F</div>
                                                                <div>S</div>
                                                            </div>
                                                        </div>
                                                        <div className="tbody">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="daypass">27</div>
                                                                <div className="daypass">28</div>
                                                                <div className="daypass">29</div>
                                                                <div className="daypass">30</div>
                                                                <div>1</div>
                                                                <div>2</div>
                                                                <div>3</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>4</div>
                                                                <div>5</div>
                                                                <div>6</div>
                                                                <div className="dayselected">
                                                                    <span>7</span>
                                                                </div>
                                                                <div>8</div>
                                                                <div>9</div>
                                                                <div>10</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>11</div>
                                                                <div>12</div>
                                                                <div>13</div>
                                                                <div>14</div>
                                                                <div>15</div>
                                                                <div>16</div>
                                                                <div>17</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>18</div>
                                                                <div>19</div>
                                                                <div>20</div>
                                                                <div>21</div>
                                                                <div>22</div>
                                                                <div>23</div>
                                                                <div>24</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>25</div>
                                                                <div>26</div>
                                                                <div>27</div>
                                                                <div>28</div>
                                                                <div>29</div>
                                                                <div>30</div>
                                                                <div className="daypass">1</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center pt-4">
                                            <div className="col-md-6 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label htmlFor="name" className="form-label fv-label">
                                                        Add Availability
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control f-v calander-icon calander-icon-rgt"
                                                        placeholder="Select work schedule"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-5 offset-md-1 my-md-4 my-3">
                                                <label htmlFor="name" className="form-label fv-label">
                                                    Set Time
                                                </label>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="fv-outer">
                                                            <input
                                                                type="text"
                                                                className="form-control f-v calander-icon calander-icon-rgt"
                                                                placeholder="10:00 Am"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-md-0 mt-3">
                                                        <div className="fv-outer">
                                                            <input
                                                                type="text"
                                                                className="form-control f-v calander-icon calander-icon-rgt"
                                                                placeholder="11:00 Am"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center pt-4">
                                            <div className="col-md-6 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label htmlFor="name" className="form-label fv-label d-block">
                                                        Recurring
                                                        <div className="form-check form-switch pull-right">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="flexSwitchCheckChecked"
                                                                defaultChecked=""
                                                            />
                                                        </div>
                                                    </label>
                                                    <ul className="dayselection">
                                                        <li>
                                                            <span>S</span>Sun
                                                        </li>
                                                        <li className="checkedday">
                                                            <span>M</span>Mon
                                                        </li>
                                                        <li>
                                                            <span>T</span>Tue
                                                        </li>
                                                        <li className="checkedday">
                                                            <span>W</span>Wed
                                                        </li>
                                                        <li>
                                                            <span>T</span>Thu
                                                        </li>
                                                        <li>
                                                            <span>F</span>Fri
                                                        </li>
                                                        <li>
                                                            <span>S</span>Sat
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-5 offset-md-1 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <input
                                                        type="text"
                                                        className="form-control f-v calander-icon calander-icon-rgt"
                                                        placeholder="Repeat until (Optional)"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <button type="submit" className="btn btn-primary btn-1" >
                            {loading ? loader : <>
                                Save &amp; Continue{" "}
                                <i className="fa fa-arrow-circle-right ms-2" />
                            </>}
                        </button>
                    </div>
                </form>
            </section >
            <Footer />
        </>
    )
}

