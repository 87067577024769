import React, { useState } from 'react'
import Footer from '../Footer'
import CustomerHeader from '../Header'
import { useLocation, useNavigate } from 'react-router-dom'
import apiClient from '../../../shared/apiClient'
import toast from 'react-hot-toast'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import '../../../App.css'
import { Controller, useForm } from 'react-hook-form'

function BackgroundCheck() {
    const payload = useLocation().state
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [convictedCrime, setConvictedCrime] = useState(true)
    const { register, handleSubmit, watch, control, setError, formState: { errors: err } } = useForm({
        defaultValues: {
        },
        mode: 'onChange'
    });

    const [formData, setFormData] = useState({
        AuthorizationDate: '',
        BackgroundCheckConsent: '',
        SocialSecurityNumber: '',
        CurrentAddress: '',
        DateOfBirth: '',
        ConvictedOfCrime: '',
        CrimeDetails: '',
        BackgroundCheckConsent: '',
    })

    const handleSave = (data) => {
        console.log(data)
        const formData = { ...payload, ...data, BackgroundCheckConsent: true }

        setLoading(true)
        apiClient.post('/Auth/VendorAccountRequest', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((res) => {
            setLoading(false)
            toast.success(res.data?.message)
            navigate('/')
        }).catch((err) => {
            setLoading(false)
            if (err?.response?.data?.errors) {
                setErrors(err?.response?.data?.errors)
            }
        })
    }


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
    return (
        <>
            <CustomerHeader />
            <section
                className="banner-background title-styled"
                style={{ backgroundImage: "url(/assets/img/banner-2.png)" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-4 text-center">
                            <h2 className="text-color1">Your Service Profile is complete!</h2>
                            <p>
                                The next step is to schedule your "SabrSafe" Screening Interview and
                                fill out the required background check details below.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="title-styled main-vendor">
                <form onSubmit={handleSubmit(handleSave)}>
                    <section className="py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">
                                            Social Security Number*
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your social security number"
                                            {...register("SocialSecurityNumber", { required: 'Social Security Number is required!' })}
                                        />
                                        {err?.SocialSecurityNumber && <p className='text-danger'>{err?.SocialSecurityNumber?.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Current Address</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your address"
                                            {...register("CurrentAddress", { required: 'Current Address is required!' })}
                                        />
                                        {err?.CurrentAddress && <p className='text-danger'>{err?.CurrentAddress?.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer" style={{ width: '100%' }}>
                                        <label htmlFor="name" className="form-label fv-label">
                                            Date of Birth
                                        </label><br />
                                        <Controller
                                            name="DateOfBirth"
                                            control={control}
                                            defaultValue={null} // Set a default value for DateOfBirth if needed
                                            rules={{ required: 'Date Of Birth is required!' }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    className="form-control f-v calander-icon"
                                                    placeholderText="DD/MM/YYYY"
                                                    selected={field.value} // This makes sure the selected date is linked to the form state
                                                />
                                            )}
                                        />

                                        {err?.DateOfBirth && <p className='text-danger'>{err?.DateOfBirth?.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">
                                            Have you been convicted of any crime?
                                        </label>
                                        <div className="d-flex">
                                            <label className="align-items-center d-flex me-2">
                                                <input
                                                    type="radio"
                                                    className="option-input radio"
                                                    name="example"
                                                    defaultChecked=""
                                                    onClick={() => setConvictedCrime(true)}
                                                    value={true}
                                                    {...register("ConvictedOfCrime", { required: 'Convicted Of Crime is required!' })}
                                                />
                                                Yes
                                            </label>
                                            <label className="align-items-center d-flex">
                                                <input
                                                    type="radio"
                                                    className="option-input radio"
                                                    name="example"
                                                    onClick={() => setConvictedCrime(false)}
                                                    value={false}
                                                    {...register("ConvictedOfCrime", { required: 'Convicted Of Crime is required!' })}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {err?.ConvictedOfCrime && <p className='text-danger'>{err?.ConvictedOfCrime?.message}</p>}
                                </div>
                            </div>
                            {convictedCrime && <div className="row">
                                <div className="col-12 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">
                                            If yes, please provide details
                                        </label>
                                        <textarea
                                            className="form-control f-v txtarea1"
                                            placeholder="Write here"
                                            defaultValue={""}
                                            name='detailsss'
                                            {...register("CrimeDetails", { required: 'Crime Details Of Crime is required!' })}
                                        />
                                        {err?.CrimeDetails && <p className='text-danger'>{err?.CrimeDetails?.message}</p>}
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <section className="py-md-5 py-3">
                            <div className="heading-bordered mb-4 pb-1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Authorization and Consent</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 my-md-4 my-3">
                                        <div className="fv-outer">
                                            <label className="form-label fv-label">
                                                I authorize the background check and consent to the use of my
                                                personal information for this purpose
                                            </label>
                                            <div className="d-flex">
                                                <label className="align-items-center d-flex me-2">
                                                    <input
                                                        type="radio"
                                                        className="option-input radio"
                                                        name="example1"
                                                        defaultChecked=""
                                                        value={true}
                                                        {...register("BackgroundCheckConsent", { required: 'BackgroundCheckConsent Of Crime is required!' })}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="align-items-center d-flex">
                                                    <input
                                                        type="radio"
                                                        className="option-input radio"
                                                        name="example1"
                                                        value={false}
                                                        {...register("BackgroundCheckConsent", { required: 'BackgroundCheckConsent Of Crime is required!' })}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                            {err.BackgroundCheckConsent && <p className='text-danger'>{err.BackgroundCheckConsent.message}</p>}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 my-md-4 my-3">
                                        <div className="fv-outer">
                                            <label htmlFor="name" className="form-label fv-label">
                                                Select Date
                                            </label>

                                            <Controller
                                                name="AuthorizationDate"
                                                control={control}
                                                defaultValue={null}
                                                rules={{ required: 'Authorization Date is required!' }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        className="form-control f-v calander-icon"
                                                        placeholderText="DD/MM/YYYY"
                                                        selected={field.value}
                                                    />
                                                )}
                                            />
                                            {err.AuthorizationDate && <p className='text-danger'>{err.AuthorizationDate.message}</p>}
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-5">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-1"
                                        >
                                            {loading ? loader : <>
                                                Submit <i className="fa fa-arrow-circle-right ms-2" />
                                            </>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </form>
            </section>
            <Footer />
        </>
    )
}

export default BackgroundCheck
