import React, { useEffect, useState } from 'react'
import CustomerHeader from '../Header'
import Footer from '../Footer'
import SearchFilter from '../../../components/SearchFilter'
import { useLocation } from 'react-router-dom'

export default function CustomSearch() {
    const prevState = useLocation().state;

    return (
        <div>
            <CustomerHeader />
            <section className="py-5 title-styled customersearch-sec">
                <div className="container pt-5">
                    {/* <div className="breads-area pt-5">
                        <div className="row align-items-center pt-5">
                            <div className="col-sm-6">
                                <nav style={{ '--bs-breadcrumb-divider': '>' }} aria-label="breadcrumb">
                                    <ol className="breadcrumb cstm-breads">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-sm-6 text-end">
                                <div className="dropdown-cstm1">
                                    <div className="dropdown">
                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Sort by: <span className="ms-2">Featured</span></span>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Featured 123</a></li>
                                            <li><a className="dropdown-item" href="#">Featured 123</a></li>
                                            <li><a className="dropdown-item" href="#">Something</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <SearchFilter />
                    <div className="row pb-5 pt-4">
                        <div className="col-lg-3 ">
                            <div className="accordion" id="accordionCustomerSearch">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Categories</button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <ul className="withchexks">
                                                    <li>Islamic Education <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Tutoring <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Baby Sitting <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>In-House chefs <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Ratings</button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <ul className="profile-stars">
                                                    <li><i className="fa fa-star" /></li>
                                                    <li><i className="fa fa-star" /></li>
                                                    <li><i className="fa fa-star" /></li>
                                                    <li><i className="fa fa-star" /></li>
                                                    <li><i className="fa fa-star" /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">Nationality</button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <ul className="withchexks">
                                                    <li>Canadian <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">Religion</button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <ul className="withchexks">
                                                    <li>Islam <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Christianity <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Judaism <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Hinduism <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">Select Availability</button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <div className="fv-outer">
                                                    <input type="text" className="form-control f-v calander-icon calander-icon-rgt" placeholder="Monday, Aug 24" />
                                                </div>
                                                <label htmlFor="name" className="form-label fv-label mt-5">Set Time</label>
                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <div className="fv-outer">
                                                            <select className="form-select f-v">
                                                                <option>10:00 Am</option>
                                                                <option>10:00 Am</option>
                                                                <option>10:00 Am</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-md-0 mt-3">
                                                        <div className="fv-outer">
                                                            <select className="form-select f-v">
                                                                <option>10:00 Am</option>
                                                                <option>10:00 Am</option>
                                                                <option>10:00 Am</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label htmlFor="name" className="form-label fv-label d-block repeat-check mb-4">Repeat
                                                    <div className="form-check form-switch pull-right">
                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked />
                                                    </div>
                                                </label>
                                                <ul className="withchexks">
                                                    <li>Sunday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Monday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Tuesday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Wednesday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Thursday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Friday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Saturday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-1 d-block w-100">Search</button>
                        </div>
                        <div className="col-lg-9 ps-lg-5">


                            <div className="profile-box px-4 px-xl-5 py-3 py-xl-4">
                                <div className="row pt-2">
                                    {prevState?.data?.length > 0 ? prevState?.data?.map((vendor, key) => (
                                        <div className="col-lg-6 my-3">
                                            <div className="inner-profile">
                                                <div className="d-flex">
                                                    <div className="profile-img">
                                                        <img src={`https://stagingApis.withSabr.com/${vendor?.profileURL}`} style={{ height: '100%', width: '100%' }} />
                                                    </div>
                                                    <div className="profile-detail ms-3 flex-grow-1">
                                                        <div className="chat-icon" />
                                                        <h4>{`${vendor?.firstName} ${vendor?.lastName}`}</h4>
                                                        <p>{vendor?.majorService}</p><p>
                                                        </p>
                                                        <Rating rating={vendor?.averageRating} />
                                                        {/* <ul className="profile-stars">
                                                            <li><span>{vendor?.averageRating}</span></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                        </ul> */}
                                                        <p>
                                                            {vendor?.experienceYears} years of Experience<br />
                                                            {vendor?.languages}<br />
                                                            {vendor?.age} years old, Canadian
                                                        </p>
                                                        <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : "No Vendors Found..."}
                                </div>
                            </div>
                            <div className="py-4 text-end">
                                {/* <ul className="paginate-custmsrch">
                                    
                                    <li className="active"><a href="#">1</a></li>
                                    <li><a href="#">4</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">4</a></li>
                                </ul> */}
                                <Pagination totalItems={5} itemsPerPage={10} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}



const Pagination = ({ totalItems, itemsPerPage }) => {
    // Calculate the total number of pages
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // State to track the current page
    const [currentPage, setCurrentPage] = useState(1);

    // Function to handle page click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        // Add any logic here to load data for the selected page
        console.log(`Loading page ${pageNumber}`);
    };

    // Generate the page buttons dynamically
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <ul className="paginate-custmsrch">
            {pageNumbers.map((pageNumber) => (
                <li
                    key={pageNumber}
                    className={pageNumber === currentPage ? "active" : ""}
                >
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault(); // Prevent default anchor behavior
                            handlePageClick(pageNumber);
                        }}
                    >
                        {pageNumber}
                    </a>
                </li>
            ))}
        </ul>
    );
};

const Rating = ({ rating = 0 }) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    const stars = [
        ...Array(fullStars).fill('fa fa-star'), // Full stars
        ...Array(halfStars).fill('fa fa-star-half-o'), // Half star
        ...Array(emptyStars).fill('fa fa-star-o') // Empty stars
    ];

    return (
        <ul className="profile-stars">
            <li><span>{rating}</span></li>
            {stars.map((starClass, index) => (
                <li key={index}><i className={starClass} /></li>
            ))}
        </ul>
    );
};
