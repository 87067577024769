import React, { useEffect, useState } from 'react'
import AuthLayout from './AuthLayout'
import { useNavigate } from 'react-router-dom'
import apiClient from '../../../shared/apiClient';
import { getLogin, loginTrue } from '../../../redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import toast from 'react-hot-toast';
import { setSessionItem } from '../../../shared/lsConfig';
import { useForm } from 'react-hook-form';


export default function Login() {
    const dispatch = useDispatch();
    const auths = useSelector(state => state.auths)
    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate();


    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    useEffect(() => {
        if (auths.isLogin) navigate('/')
        if (auths.message) toast.success(auths?.message)
    }, [auths])

    const handleLogin = (data) => {
        dispatch(getLogin(data))
    }

    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
    return (
        <AuthLayout>
            <div className="login-form-area px-3 px-lg-5">
                <h2>Login</h2>
                <ul className="socials-2 my-3">
                    <li>
                        <GoogleOAuthProvider clientId="317913382060-1df0u8uphbnv99rg66k7kc9s2rtt4cbf.apps.googleusercontent.com">
                            <GoogleLoginButton />
                        </GoogleOAuthProvider>
                    </li>

                    <li>
                        <a href="#" target="_blank" className="fa fa-apple" />
                    </li>
                </ul>
                <p className="pt-4 mb-0 mb-md-2">
                    Or use your email for Registration!
                </p>
                <div className="py-3 px-lg-5">
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <div className="my-4">
                            <input
                                type="text"
                                id="nameemail"
                                name='username'
                                className="form-control f-0"
                                placeholder="Enter email or username"
                                {...register("username", { required: "Username is required!" })}
                            />
                            {errors.username && <p className='text-danger text-start'>{errors.username.message}</p>}

                        </div>
                        <div className="m4-4 icon-over">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="passwrd"
                                name='password'
                                className="form-control f-0"
                                placeholder="Password"
                                {...register("password", { required: "Password is required!" })}
                            />
                            <i
                                className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                id="togglePassword"
                                role='button'
                                onClick={() => setShowPassword(!showPassword)}
                            />

                            {errors.password && <p className='text-danger text-start'>{errors.password.message}</p>}
                        </div>
                        <div style={{ textAlign: 'left', marginTop: '5px' }}>
                            {auths?.errMsg && <span className='text-danger pt-3'>{auths.errMsg}</span>}
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary btn-1 d-block w-100 my-4"
                        >
                            {auths.loading ? loader : "Login"}
                        </button>
                        <p
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/forget-password')}
                        >
                            Forget Password?
                        </p>
                    </form>
                </div>
            </div>
        </AuthLayout>
    )
}

const GoogleLoginButton = () => {
    const dispatch = useDispatch();

    const handleLoginSuccess = async (tokenResponse, _) => {
        const token = tokenResponse.access_token;

        try {
            const res = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?id_token=${token}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json'
                }
            });

            const data = {
                token: token,
                email: res.data?.email,
                provider: 'Google',
                userRole: "Customer"
            };

            const response = await apiClient.post('/Auth/ThirdPartyLogin', data);
            setSessionItem('auth_token', response.data?.token);
            setSessionItem('refresh_token', response.data?.refreshToken);
            setSessionItem('userRole', res.data?.userRole)
            toast.success(response.data?.message);
            dispatch(loginTrue())

        } catch (err) {
            if (err.response) {
                // Server responded with a status other than 200 range
                toast.error(err.response.data.message || "Network Error!");
            } else {
                // CORS or network error
                toast.error('Google Authentication Error');
            }
        }
    };
    const handleLoginError = (error) => {
        console.log("Login Failed:", error);
    };

    const login = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginError,

    });

    return <i role='button' onClick={login} className="fa fa-google" style={{ margin: '9px' }} />;
};
