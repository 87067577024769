import React from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { getLogout } from '../../redux/reducers/authSlice';
import toast from 'react-hot-toast';

export default function CustomerHeader() {
    const navigate = useNavigate();
    const auths = useSelector(state => state.auths)
    const dispatch = useDispatch();

    return (
        <section className="header-top fixed-top py-2" id="header-sroll">
            <header className="py-2">
                <nav className="navbar navbar-expand-md" id="menu-primary">
                    <div className="container">
                        <Link className='navbar-brand' to={'/'}>
                            <img src={`/assets/img/logo.png`} alt="image" className="img-fluid" height={70} width={200} />
                        </Link>

                        {auths.isLogin ? (
                            <div className='d-flex justify-content-center align-items-center'>
                                <div style={{ position: 'relative' }}>
                                    <span
                                        role='button'
                                        id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
                                    > <i class="fa fa-solid fa-bell"></i></span>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ position: 'absolute', right: '100%' }}>
                                        <li><a class="dropdown-item" href="#">Greeting User</a></li>
                                    </ul>
                                </div>


                                <ul className="navbar-nav flex-grow-1">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login">
                                            <i className="fa fa-user me-2" /> My Account
                                        </Link>
                                    </li>
                                </ul>
                                <Button onClick={() => {
                                    dispatch(getLogout())
                                    toast.success("Logout Successfully!")
                                    navigate('/login')
                                }} variant='success' style={{ height: '40px' }}>Logout</Button>
                            </div>
                        ) :
                            <>

                                <div style={{ position: 'relative' }}>
                                    <span
                                        role='button'
                                        id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
                                    > <i class="fa fa-solid fa-bell"></i></span>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ position: 'absolute', right: '100%' }}>
                                        <li><a class="dropdown-item" href="#">Greeting User</a></li>
                                    </ul>
                                </div>

                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasNavbar"
                                    aria-controls="offcanvasNavbar"
                                >
                                    <span className="fa fa-bars" />
                                </button>
                                <div
                                    className="offcanvas offcanvas-end"
                                    tabIndex={-1}
                                    id="offcanvasNavbar"
                                    aria-labelledby="offcanvasNavbarLabel"
                                >
                                    <div className="offcanvas-header">
                                        <Link className="navbar-brand offcanvas-title" to="/">
                                            <img src={`/assets/img/logo.png`} alt="image" className="img-fluid" />
                                        </Link>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="offcanvas-body">
                                        <ul className="navbar-nav justify-content-end flex-grow-1">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/login">
                                                    <i className="fa fa-user me-2" /> Account
                                                </Link>
                                            </li>
                                            <li className="nav-item d-block d-md-none">
                                                <Link className="nav-link" to={'/welcome-screen'}>
                                                    <i className="fa fa-sign-in me-2" /> Sign Up
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <form className="mt-1 d-md-flex d-none">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-1"
                                        onClick={() => {
                                            navigate('/welcome-screen')
                                        }}
                                    >
                                        Sign Up <i className="fa fa-arrow-circle-right ms-2" />
                                    </button>
                                </form>
                            </>}
                    </div>
                </nav>
            </header>
        </section>

    )
}
