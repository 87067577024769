import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getServices, searchCity } from '../redux/reducers/homeSlice';
import SearchableDropdown from './SearchableDropdown';
import apiClient from '../shared/apiClient';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import CitySelect from './SelectSearch';
import { Controller, useForm } from 'react-hook-form';

function SearchFilter() {
    const { services, searchCities } = useSelector(state => state.home)
    const [cityName, setCityName] = useState('');
    const [search, setSearch] = useState(true)
    const [loading, setLoading] = useState(false)
    const [cities, setCities] = useState([])



    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const lastSearch = sessionStorage.getItem('search_data') ? JSON.parse(sessionStorage.getItem('search_data')) : {}
    const { register, control, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: {
            ...lastSearch
        }
    })

    useEffect(() => {
        if (Object.keys(lastSearch).length > 0) fetchVendors(lastSearch)
    }, [])

    useEffect(() => {
        setCities(searchCities)
    }, [searchCities])

    useEffect(() => {
        dispatch(getServices())
    }, [])
    useEffect(() => {
        const handler = setTimeout(() => {
            if (cityName) {
                setCities([])
                dispatch(searchCity({ searchText: cityName, countryName: null, pageNumber: 1, pageSize: 10 }));
            }
        }, 0);
        return () => {
            clearTimeout(handler);
        };
    }, [cityName, dispatch]);

    const fetchVendors = (values, click = false) => {
        setLoading(true)
        sessionStorage.setItem('search_data', JSON.stringify(values))
        apiClient.get('/Vendor/SearchVendor', {
            params: values
        }).then((res) => {
            setLoading(false)
            toast.success(res.data?.message)
            click && navigate('/custom-search', { state: { data: res.data?.vendors, formData: values, total: res.data?.totalCount } })
        }).catch((err) => {
            toast.error('Network Error.')
            setLoading(false)
        })
    }

    const handleSave = (values) => {
        fetchVendors(values, true)
    }


    const loadCities = async (inputValue, callback) => {
        if (inputValue) {
            dispatch(searchCity({
                searchText: inputValue,
                countryName: null,
                pageNumber: 1,
                pageSize: 10
            }))
        }
        const cityOptions = searchCities.length > 0 && searchCities.map(city => ({
            label: city.cityName,
            value: city.cityID,
        }));
        callback(cityOptions);
    };


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )

    return (
        <>
            {/* Desktop Form */}
            <form className="form-banner d-lg-block d-none" onSubmit={handleSubmit(handleSave)}>
                <h3>Find Service</h3>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="fm-bnr-outer my-2">
                            <Controller
                                name="ServiceId"
                                control={control}
                                rules={{ required: 'Service Id is required!' }}
                                render={({ field }) => (
                                    <select
                                        {...field}
                                        className="form-select f-1"
                                    >
                                        <option value="">Select Service</option>
                                        {services.map((service, index) => (
                                            <option key={index} value={service.ServiceID}>
                                                {service.ServiceName}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            />
                            {errors.ServiceId && <span className='text-danger'>{errors.ServiceId.message}</span>}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="fm-bnr-outer my-2">
                            <div className="row">
                                <div className="col-lg-3">
                                    {/* <CitySelect
                                        name="cityId"
                                        loadOptions={loadCities}
                                        handleChange={({ label, value }) => setCityName(value)}
                                    /> */}
                                    <Controller
                                        name="cityId"
                                        control={control}
                                        rules={{ required: 'City is required' }}
                                        render={({ field }) => (
                                            <CitySelect
                                                {...field}
                                                loadOptions={loadCities}
                                                handleChange={({ label, value }) => field.onChange(value)} // Update form state
                                            />
                                        )}
                                    />
                                    {errors.cityId && <span className='text-danger'>{errors.cityId.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="input-group b-left">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa fa-map-marker" />
                                        </span>
                                        <Controller
                                            name="ZipPostalCode"
                                            control={control}
                                            rules={{
                                                required: 'Zip code is required!',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Zip code must be number!',
                                                },
                                            }}
                                            render={({ field }) => (
                                                <input
                                                    type="text"
                                                    className="form-control f-1"
                                                    placeholder="Zip Code"
                                                    maxLength={5}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors.ZipPostalCode && <span className='text-danger'>{errors.ZipPostalCode.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="b-left">
                                        {/* <select className="form-select f-1" name='Distance' required onChange={(e) => setFormData({ ...formData, Distance: parseInt(e.target.value) })}>
                                            <option value={1}>1 Miles</option>
                                            <option value={2}>2 Miles</option>
                                            <option value={3}>3 Miles</option>
                                        </select> */}
                                        <Controller
                                            name="Distance"
                                            control={control}
                                            rules={{ required: 'Distance is required!' }}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-select f-1"
                                                >
                                                    <option value="">Select Distance</option>
                                                    <option value={1}>1 Mile</option>
                                                    <option value={2}>2 Miles</option>
                                                    <option value={3}>3 Miles</option>
                                                </select>
                                            )}
                                        />
                                    </div>
                                    {errors.Distance && <span className="text-danger" style={{ position: 'absolute' }}>{errors.Distance.message}</span>}
                                </div>
                                <div className="col-lg-3 text-lg-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-1 mt-4 mt-lg-0"
                                    >
                                        {loading ? loader : "Search"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form className="form-banner d-block d-lg-none" onSubmit={handleSubmit(handleSave)}>
                <div className="bg-white px-3 py-4">
                    <h3>Find Service</h3>
                    <div className="row">
                        <div className="col-12 my-2">
                            <div className="fm-brdr">

                                {/* <select className="form-select f-1" onChange={(e) => setFormData({ ...formData, ServiceId: e.target.value })}>
                                    {services.map((service, index) => <option key={index} value={service.ServiceID}>{service.ServiceName}</option>)}
                                </select> */}
                                <Controller
                                    name="ServiceId"
                                    control={control}
                                    rules={{ required: 'Service Id is required!' }}
                                    render={({ field }) => (
                                        <select
                                            {...field}
                                            className="form-select f-1"
                                        >
                                            <option value="">Select Service</option>
                                            {services.map((service, index) => (
                                                <option key={index} value={service.ServiceID}>
                                                    {service.ServiceName}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                />
                            </div>
                            {errors.ServiceId && <span className='text-danger'>{errors.ServiceId.message}</span>}
                        </div>
                        <div className="col-12 my-2">
                            <div className="fm-brdr">
                                {/* <CitySelect
                                    name="cityId"
                                    loadOptions={loadCities}
                                    handleChange={({ label, value }) => setCityName(value)}
                                /> */}
                                <Controller
                                    name="cityId"
                                    control={control}
                                    rules={{ required: 'City is required' }}
                                    render={({ field }) => (
                                        <CitySelect
                                            {...field}
                                            loadOptions={loadCities}
                                            handleChange={({ label, value }) => field.onChange(value)} // Update form state
                                        />
                                    )}
                                />
                            </div>
                            {errors.cityId && <span className='text-danger'>{errors.cityId.message}</span>}
                        </div>
                    </div>
                    <div className="fm-brdr my-2">
                        <div className="row">
                            <div className="col-6">
                                {/* <select className="form-select f-1" onChange={(e) => setFormData({ ...formData, Distance: e.target.value })}>
                                    <option value={1}>1 Miles</option>
                                    <option value={2}>2 Miles</option>
                                    <option value={3}>3 Miles</option>
                                </select> */}
                                <Controller
                                    name="Distance"
                                    control={control}
                                    rules={{ required: 'Distance is required!' }}
                                    render={({ field }) => (
                                        <select
                                            {...field}
                                            className="form-select f-1"
                                        >
                                            <option value="">Select Distance</option>
                                            <option value={1}>1 Mile</option>
                                            <option value={2}>2 Miles</option>
                                            <option value={3}>3 Miles</option>
                                        </select>
                                    )}
                                />

                            </div>
                            <div className="col-6 b-left">
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-map-marker" />
                                    </span>
                                    <Controller
                                        name="ZipPostalCode"
                                        control={control}
                                        rules={{
                                            required: 'Zip code is required!',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Zip code must be number!',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <input
                                                type="text"
                                                className="form-control f-1"
                                                placeholder="Zip Code"
                                                maxLength={5}
                                                {...field}
                                            />
                                        )}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '-10px', marginBottom: '10px' }}>
                        <div className='col-6'>
                            {errors.Distance && <span className="text-danger">{errors.Distance.message}</span>}
                        </div>
                        <div className='col-6'>
                            {errors.ZipPostalCode && <span className='text-danger'>{errors.ZipPostalCode.message}</span>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col my-2">
                            <button
                                type="submit"
                                className="btn btn-primary btn-1 btn-block w-100"
                            >
                                {loading ? loader : "Search"}
                            </button>
                        </div>
                    </div>
                </div>
            </form >
        </>
    )
}

export default SearchFilter