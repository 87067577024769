import React, { useEffect } from 'react'

export default function Category({ services = [] }) {

    useEffect(() => {
        const $ = window.jQuery;
        $('#servicebox-carousel').owlCarousel({
            center: false,
            items: services.length ? services.length : 1,
            dots: false,
            loop: true,
            margin: 20,
            responsiveClass: true,
            nav: true,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1200: {
                    items: 4
                }
            }
        });

        return () => {
            $('#servicebox-carousel').owlCarousel('destroy');
        };

    }, [services]);

    const images = ['/assets/img/se-1.png', '/assets/img/se-2.png', '/assets/img/se-3.png', '/assets/img/se-1.png']

    return (
        <section className="menus-sec py-5">
            <div className="container py-3 py-lg-5">
                <div className="row mb-4">
                    <div className="col title-styled">
                        <div className="border-styled pb-2">
                            <h2>Popular Categories</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className="owl-carousel owl-theme cstm-theme-navs cstm-navs-center"
                            id="servicebox-carousel"
                        >
                            {services.map((service, index) => (
                                <div className="item" id='category-card' key={index}>
                                    <div className="service-box" style={{ height: '300px', background: 'none' }}>
                                        <img
                                            src={`https://stagingapis.withsabr.com/${service.IconPath}`}
                                            style={{ height: '100%', width: '100%' }}
                                            alt="img"
                                            className="img-fluid"
                                        />
                                        <h4>{service.ServiceName}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
