import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import apiClient from '../shared/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { searchCity } from '../redux/reducers/homeSlice';


export default function CitySelect({ loadOptions = () => { }, handleChange }) {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none', boxShadow: 'none',
            marginTop: '6px',
            // padding: '5px',
            '&:hover': {
                border: 'none',
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#00CC66',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
    };

    return (
        <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            styles={customStyles}
            placeholder="Select city"
            onChange={handleChange}
        />
    );
}
